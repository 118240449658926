import React, { useState } from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import playIcon from "../images/play-circle-regular.svg"
import barqImg from "../images/c-barq-logo-white-and-blue.png"
import ModalVideo from "react-modal-video"
import Seo from "../components/seo"

const IndexPage = () => {
  const [isOpen, setOpen] = useState(false)

  return (
    <Layout title="testing" className="bg-image" transparent showDog>
      <Seo title="Penn Vet Behavior App" description="The Penn Vet Behavior App is a consultation and education service available to pet owners and veterinarians nationally and internationally." />
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="IBJJUGe-nCk"
        onClose={() => setOpen(false)}
      />
      <div className="px-2 mt-16 lg:mt-auto lg:max-w-[55%]">
        <h1 className="text-3xl sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl font-medium text-white mb-4">
          PennVet Behavior App
        </h1>
        <StaticImage src="../images/good-dog-img.png" alt="Good dog" />
        <div className="flex flex-col md:flex-row gap-4 mt-4">
          <a
            href="https://pennvet.app.intellivets.com/"
            className="flex w-full justify-center items-center border-2 border-gray-300 py-3 sm:px-5 sm:py-4 text-white gap-4 text-2xl sm:text-3xl bg-[#73153d] min-w-[236px]"
          >
            Enter
          </a>
          <button
            onClick={() => setOpen(true)}
            className="flex w-full justify-center items-center border-2 border-gray-300 py-3 sm:px-5 sm:py-4 text-white gap-4 text-2xl sm:text-3xl min-w-[236px]"
          >
            <img style={{ height: "30px" }} src={playIcon} />
            Learn More
          </button>
        </div>
        <div className="mt-4 text-center flex flex-col items-center mb-4">
          <a
            href="https://vetapps.vet.upenn.edu/cbarq/"
            className="flex w-full justify-center items-center border-2 border-gray-300 py-3 sm:px-5 sm:py-4 text-white gap-4 text-2xl sm:text-3xl min-w-[236px]"
          >
            Start with a free assessment tool!
          </a>
          <img className="mt-4" style={{ height: "50px" }} src={barqImg} />
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
